<template>
  <div class="search">
    <!-- <section class="section parallax-container parallax-header context-light" data-parallax-img="/img/search.jpg">
      <div class="parallax-content">
        <div class="parallax-header__inner">
          <div class="parallax-header__content">
            <div class="container">
              <div class="row row-fix justify-content-sm-center">
                <div class="col-md-10 col-xl-8">
                  <h2 class="heading-22">A Wide Variety of High Quality Services</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="banner-search">
      <img src="/img/search.jpg" alt="cuentanos" />
    </section>

    <section class="section context-dark bg-accent section-cta">
      <div class="container container-wide">
        <div class="row row-30 align-items-center">
          <div class="col-xl-12 wow blurIn">
            <h3>
              <span class="font-weight-bold">{{ $t('lang.search.section_1.title') }}</span>
            </h3>
            <h5 style="margin-top: 10px">
              {{ $t('lang.search.section_1.subtitle') }}
            </h5>

            <!-- <br>
							<a class="button button-gray-light-outline">GET IT NOW</a> -->
          </div>
        </div>
      </div>
    </section>

    <!-- Form -->
    <v-app>
      <v-main>
        <section id="company-form">
          <div class="page-content">
            <div class="wizard-heading">
              <h2 class="v-desktop">
                <b>{{ $t('lang.search.section_2.title') }}</b>
              </h2>
              <h2 class="v-mobile">
                <b>{{ $t('lang.search.section_2.title2') }}</b>
              </h2>
            </div>
            <div class="wizard-v6-content">
              <div class="wizard-form">
                <form-professional />
              </div>
            </div>
          </div>
        </section>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import FormProfessional from '../shared/components/form/form-professional.vue';
export default Vue.extend({
  name: 'Search',
  metaInfo: {
    title: 'Buscas talentos para tu empresa | Connecting Tech People',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          '🔥 Únete a connecting tech people🚀te ayudaremos a encontrar el proyecto que necesitas para tu éxito profesional'
      }
    ]
  },
  data: () => ({}),
  components: {
    FormProfessional
  }
});
</script>

<style>
.banner-search {
  height: 35em;
  width: 100%;
}

.banner-search img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1000px) {
  .banner-search {
    height: 25em;
  }
}

@media (max-width: 600px) {
  .banner-search {
    height: 100%;
  }
}
</style>
